import React from 'react'
import styled from 'styled-components'

import zIndexes from '../../constants/zIndexes'
import Colors from '../../constants/colors'

const AppFooter = styled.footer.attrs((props) => ({
  // @ts-ignore
  darkMode: props.darkMode
}))`
  background: ${(props) => (props.darkMode ? Colors.aura : Colors.albert)};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem 1rem;
  color: white;
  text-align: center;
  z-index: ${zIndexes.top + 100};
`

export type FooterProps = {
  versions?: [string, string][]
  darkMode?: boolean
}

const Footer: React.FC<FooterProps> = ({versions, children, darkMode}) => (
  <AppFooter darkMode={darkMode}>
    {versions
      ? versions.map(([name, version], index) => {
          return (
            <span key={`${name}-${version}`}>
              <strong>{name}: </strong>
              {version} {index < versions.length - 1 && '|'}{' '}
            </span>
          )
        })
      : ''}
    {children}
  </AppFooter>
)

export default Footer
