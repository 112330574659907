import styled from 'styled-components'
import React from 'react'
import colors from '../../../constants/colors'

const Styles = styled.div`
  .dropdown-menu {
    background-color: ${colors.aura};
  }
  .dropdown-item {
    color: white;
  }
  .dropdown-item:hover {
    background-color: ${colors.lightJuno};
  }
`

interface Props {
  enableDarkMode: boolean
  children?: React.ReactNode
}

const DarkMode: React.FunctionComponent<Props> = (props: Props) => {
  if (props.enableDarkMode) {
    return <Styles>{props.children}</Styles>
  } else {
    return <>{props.children}</>
  }
}

export default DarkMode
