import React, {useState} from 'react'
import Webcam from 'react-webcam'

function LoginCamera() {
  const [useWebcam, setUseWebcam] = useState<boolean>(false)

  const toggleCam = () => setUseWebcam(!useWebcam)

  const videoConstraints = {
    width: 220,
    height: 180,
    facingMode: 'user'
  }

  return (
    <>
      <defs>
        <style>
          {`
          #loginBackgroundWrapper {
            position: fixed;
            display: block;
            width: 110px;
            height: 90px;
            cursor: pointer;
          }

          .loginCameraTrigger {
            cursor: pointer;
          }
        `}
        </style>

        <clipPath id="loginBackgroundCameraArea">
          <path d="M212.1 84.4 h104.4l-11.9 83.8h-87z" />
        </clipPath>
      </defs>

      <path className="loginCameraTrigger" d="M212.1 84.4 h104.4l-11.9 83.8h-87z" fill="black" onClick={toggleCam} />

      {useWebcam && (
        <g id="loginBackgroundGroup" clipPath="url(#loginBackgroundCameraArea)" onClick={toggleCam}>
          <foreignObject x="212" y="82" width="110" height="90">
            <div id="loginBackgroundWrapper">
              <Webcam id="loginBackgroundWrapperVideo" width={110} height={90} videoConstraints={videoConstraints} />
            </div>
          </foreignObject>
        </g>
      )}

      <ellipse className="loginCameraTrigger" cx={264.6} cy={89.5} fill="#414353" opacity={0.1} rx={2} ry={1.8} onClick={toggleCam} />
      <circle className="loginCameraTrigger" cx={264.6} cy={89.5} fill="#414353" r={1.6} onClick={toggleCam} />
      <ellipse className="loginCameraTrigger" cx={264.8} cy={88.9} fill="#aeb2c2" rx={0.8} ry={0.6} onClick={toggleCam} />
      <path
        className="loginCameraTrigger"
        d="M281.7 84.4l-64.7 74 .6 9.8H252l63.8-79 .7-4.8z"
        fill="#414353"
        opacity={0.1}
        onClick={toggleCam}
      />
    </>
  )
}

export default LoginCamera
