import React, {useContext} from 'react'

type Context = {
  environment: string
  game: string
  proxyUrl: string
  endpoints: {
    gameServiceEndpoint: string
    userServiceEndpoint: string
    cmsServiceEndpoint: string
    socketServiceEndpoint: string
    paymentServiceEndpoint: string
  }
}

export const ConfigContext = React.createContext<Context>({
  environment: '',
  game: '',
  proxyUrl: '',
  endpoints: {
    gameServiceEndpoint: '',
    userServiceEndpoint: '',
    cmsServiceEndpoint: '',
    socketServiceEndpoint: '',
    paymentServiceEndpoint: ''
  }
})

export const useConfig = () => {
  const context = useContext(ConfigContext)
  if (!context.environment || !context.game || !context.proxyUrl || !context.endpoints)
    throw new Error(
      'web-common-react-ts: The application must be wrapped in a ConfigContext provider: https://web-common-react-ts.storybook.shared.apelabs.net/?path=/story/configuration-configcontext--page.'
    )
  return context
}

export default {
  Consumer: ConfigContext.Consumer,
  Provider: ConfigContext.Provider
}
