import React from 'react'

import {useAuth} from '../AuthContext'
import {ServicePermissions} from 'model'

export interface PermissionProtectedProps<Permissions> {
  id: Permissions
  fallback?: React.ReactElement
  children: React.ReactNode
}

interface Options {
  disableChecking?: boolean
}

type PermissionDescription = {
  id: string
  service: string
}

const hasPermission = ({id, service}: PermissionDescription, permissions: Array<ServicePermissions>): boolean => {
  const serviceData = permissions.find((servicePermission: ServicePermissions) => servicePermission.service === service)
  const servicePermissions = serviceData && serviceData.permissions
  return servicePermissions ? !!servicePermissions.find((permission: string) => permission === id) : false
}

export const createUsePermission = <Permissions extends string>(service: string, options?: Options) => {
  return (id: Permissions) => {
    const {permissions} = useAuth()

    if (options && options.disableChecking) return true

    return hasPermission({id, service}, permissions)
  }
}

export const createPermissionProtected = <Permissions extends string>(service: string, options?: Options) => {
  return ({id, fallback, children}: PermissionProtectedProps<Permissions>) => {
    const {permissions} = useAuth()

    if (options && options.disableChecking) return <>{children}</>
    return hasPermission({id, service}, permissions) ? <>{children}</> : fallback || null
  }
}
