import React from 'react'
import {Button as ReactstrapButton, Spinner, ButtonProps} from 'reactstrap'
import styled from 'styled-components'

import VerticalMargin from '../../layout/Margins/Vertical'

export type ButtonColor = 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'link'

export type ButtonSize = 'sm' | 'md' | 'lg'

export interface Props extends ButtonProps {
  size?: ButtonSize
  color?: ButtonColor
  isSuccess?: boolean
  isLoading?: boolean
  'data-testid'?: string
}

const StyledButton = styled(ReactstrapButton)`
  transition: all 0.5s ease;
`

const SpinnerWrapper = styled.div`
  display: inline-flex;
  vertical-align: middle;
  padding-bottom: 2px;
`

/**
 * This button extends on Reactstrap's default button and adds a visual indicator when it's loading
 * and when it's finished, via props.
 */
const Button: React.FC<Props> = ({
  children,
  color = 'primary',
  disabled = false,
  type = 'button',
  size = 'md',
  isSuccess = false,
  isLoading = false,
  ...domProps
}: Props) => {
  return (
    <StyledButton data-testid="button" color={isSuccess ? 'success' : color} disabled={disabled} type={type} size={size} {...domProps}>
      {children}

      {(isSuccess || isLoading) && <VerticalMargin margin={2} />}
      {isLoading && (
        <SpinnerWrapper>
          <Spinner data-testid="button-loading" size="sm" />
        </SpinnerWrapper>
      )}
      {isSuccess && <i data-testid="button-success" className="fa fa-check" aria-hidden="true" />}
    </StyledButton>
  )
}

export default Button
