import React from 'react'
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap'

import {BootstrapColor, BootstrapSize} from '../../constants/bootstrap'
import DarkMode from './DarkMode'

export type Environment = {
  name: string
  url: string
}

export interface Props {
  env?: string
  config?: Environment[]
  color?: BootstrapColor
  size?: BootstrapSize
  darkMode?: boolean
}

const EnvironmentSwitcher: React.FC<Props> = ({env, config, color = 'primary', size = 'md', darkMode = false}) =>
  env ? (
    <DarkMode enableDarkMode={darkMode}>
      <UncontrolledDropdown data-testid="environment-switcher">
        <DropdownToggle data-testid="environment-switcher-dropdown" size={size} caret={!!config} color={color}>
          {env.toUpperCase()}
        </DropdownToggle>
        {config && (
          <DropdownMenu>
            {config.map(({name, url}) => (
              <DropdownItem key={name} href={url}>
                {name.toLowerCase()}
              </DropdownItem>
            ))}
          </DropdownMenu>
        )}
      </UncontrolledDropdown>
    </DarkMode>
  ) : null

export default EnvironmentSwitcher
