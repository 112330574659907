import React, {Component} from 'react'
import {Form, FormFeedback, Input} from 'reactstrap'
import styled from 'styled-components'

import Button from '../Button'
import VerticalMargin from '../../layout/Margins/Vertical'

const Icon = styled.i`
  color: blue;
  cursor: pointer;
  margin-left: 0.25rem;
`

export type EditableStringProps = EditableStringBaseProps | EditableStringWithValidation

type EditableStringBaseProps = {
  initialValue: string
  onSave: (value: string) => void | Promise<void>
  maxLength?: number
  disabled?: boolean
}

const hasValidation = (props: EditableStringBaseProps | EditableStringWithValidation): props is EditableStringWithValidation => {
  return (props as EditableStringWithValidation).isValidFunc !== undefined
}

type EditableStringWithValidation = EditableStringBaseProps & EditableStringValidationProps

type EditableStringValidationProps = {
  isValidFunc: (value: string) => boolean
  invalidText: string
}

type EditableStringState = {
  value: string
  editing: boolean
  saving: boolean
}

export default class EditableString extends Component<EditableStringProps, EditableStringState> {
  state: EditableStringState = {
    value: this.props.initialValue,
    editing: false,
    saving: false
  }

  save = async () => {
    this.setState({saving: true})

    try {
      await this.props.onSave(this.state.value)
      this.setState({editing: false, saving: false})
    } catch (e) {
      this.setState({editing: false, saving: false})
    }
  }

  edit = () => {
    this.setState({editing: true})
  }

  cancel = () => {
    this.setState({editing: false, value: this.props.initialValue})
  }

  componentDidUpdate(prevProps: Readonly<EditableStringProps>) {
    if (this.props.initialValue !== prevProps.initialValue) {
      this.setState({value: this.props.initialValue, editing: false})
    }
  }

  render() {
    const {initialValue, maxLength, disabled} = this.props
    const {value, editing, saving} = this.state
    const invalid = hasValidation(this.props) ? !this.props.isValidFunc(value) : false
    const invalidText = hasValidation(this.props) ? this.props.invalidText : ''

    return (
      <div>
        {editing ? (
          <Form
            inline
            onSubmit={(e) => {
              e.preventDefault()
              e.stopPropagation()
              this.save()
            }}
          >
            <Input
              value={value}
              onChange={(e) => this.setState({value: e.target.value})}
              autoFocus
              maxLength={maxLength}
              invalid={invalid}
            />
            <VerticalMargin />
            <Button color="primary" onClick={this.save} disabled={value === initialValue || invalid} isLoading={saving}>
              <i className="fa fa-check" title="Save" />
            </Button>
            <VerticalMargin />
            <Button onClick={this.cancel} disabled={saving}>
              <i className="fa fa-close" title="Cancel" />
            </Button>
            {invalid && <FormFeedback>{invalidText}</FormFeedback>}
          </Form>
        ) : (
          <span>
            {value.toString()} {!disabled && <Icon className="fa fa-edit" onClick={this.edit} />}
            {invalid && <div className="text-danger small">{invalidText}</div>}
          </span>
        )}
      </div>
    )
  }
}
