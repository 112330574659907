import React, {useState} from 'react'
import {Button, ButtonGroup, Spinner} from 'reactstrap'
import styled, {css} from 'styled-components'

import VerticalMargin from '../../layout/Margins/Vertical'

type Color = 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'link'

export interface Props {
  color?: Color
  confirmText: string
  onClick?: React.MouseEventHandler<any>
  children: React.ReactElement | Array<React.ReactElement> | string
  disabled?: boolean
  type?: 'submit' | 'reset' | 'button'
  isLoading?: boolean
  isSuccess?: boolean
  title?: string
  size?: 'sm' | 'md' | 'lg'
  'data-testid'?: string
  expanded?: boolean
  darkMode?: boolean
}

const AnimatedButton = styled(Button)<{$isActionable?: boolean}>`
  transition: all 0.5s ease;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${(props) => (props.$isActionable ? '400px' : '300px')};
  /* Ugly hacks below, just overriding reactstrap to make things look better. Don't repeat 🤪 */
  ${(props) =>
    !props.$isActionable
      ? css`
          border-bottom-right-radius: 0.25rem !important;
          border-top-right-radius: 0.25rem !important;
        `
      : ''}
`

const AnimatedCancelButton = styled(Button)<{$show?: boolean}>`
  transition: all 0.5s ease;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${(props) => (props.$show ? '50px' : '0')};
  padding: ${(props) => (props.$show ? '0 15px' : '0')};
  opacity: ${(props) => (props.$show ? '1' : '0')};
`

/**
 * A button that needs to be pressed a second time to confirm. It receives the same props
 * as the default button.
 */
const Index = ({
  children,
  color = 'primary',
  confirmText,
  onClick,
  title,
  type = 'button',
  size = 'md',
  disabled = false,
  isLoading = false,
  isSuccess = false,
  expanded = false,
  ...domProps
}: Props) => {
  const [clicked, setClicked] = useState(expanded)
  const isActionable = clicked && !isLoading && !isSuccess

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isActionable) {
      onClick && onClick(event)
    } else {
      event.preventDefault()
      setClicked(true)
    }
  }

  return (
    <ButtonGroup>
      <AnimatedButton
        data-testid="confirm-button"
        $isActionable={isActionable}
        color={isSuccess ? 'success' : color}
        type={isActionable ? type : 'button'}
        onClick={handleClick}
        disabled={disabled || isLoading || isSuccess}
        title={title}
        size={size}
        {...domProps}
      >
        {isActionable ? confirmText : children}

        {clicked && <VerticalMargin margin={2} />}
        {clicked && isLoading && <Spinner data-testid="confirm-button-loading" size="sm" />}
        {clicked && !isLoading && <i data-testid="confirm-button-tick-icon" className="fa fa-check" aria-hidden="true" />}
      </AnimatedButton>

      <AnimatedCancelButton data-testid="confirm-button-cancel" size={size} $show={isActionable} onClick={() => setClicked(false)}>
        <i className="fa fa-times" aria-hidden="true" />
      </AnimatedCancelButton>
    </ButtonGroup>
  )
}

export default Index
