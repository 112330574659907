import React, {PropsWithChildren} from 'react'
import styled, {css} from 'styled-components'

type TitleTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5'

export interface Props extends PropsWithChildren<any> {
  tag?: TitleTag
  first?: boolean
}

const BaseTitle = css<{first: boolean}>`
  font-size: 20px;
  padding-bottom: 15px;
  margin: ${(props) => (props.first ? '0 0 20px 0' : '50px 0 20px 0')};
  border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
`

const TitleTags = {
  h1: styled.h1`
    ${BaseTitle}
  `,
  h2: styled.h2`
    ${BaseTitle}
  `,
  h3: styled.h3`
    ${BaseTitle}
  `,
  h4: styled.h4`
    ${BaseTitle}
  `,
  h5: styled.h5`
    ${BaseTitle}
  `
}

/**
 * A title with a bottom border, that can be used to signal the start of a section.
 */
const SectionTitle: React.FC<Props> = ({children, tag = 'h2', first = false}: Props) => {
  const Title = TitleTags[tag]

  return <Title first={first}>{children}</Title>
}

export default SectionTitle
