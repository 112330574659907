import React, {PropsWithChildren, useState} from 'react'
import {Button, Collapse, Container, Dropdown, DropdownToggle, Nav, NavbarToggler, NavItem, NavLink} from 'reactstrap'
import {NavLink as RouterNavLink, useLocation} from 'react-router-dom'

import spaceApeLogo from './sa-logo.png'
import EnvironmentSwitcher, {Environment} from '../EnvironmentSwitcher'
import {BootstrapColor} from '../../constants/bootstrap'
import {signOut} from '../../auth/AuthProtected/utils'

import {ButtonContainer, DropdownItem, GameLogo, HomepageLink, LogoText, SpaceApeLogo, Submenu, WrappedNavbar} from './styles'

export type Props = PropsWithChildren<{
  env?: string
  envConfig?: Environment[]
  fixed?: boolean
  appTitle: string
  sections?: HeaderItemProps[]
  logo?: React.ReactElement | Array<React.ReactElement> | string
  darkMode?: boolean
}>

type HeaderItemLeafProps = {
  title: string
  link: string
  show?: boolean
  darkMode?: boolean
}

type HeaderItemGroupingProps = {
  title: string
  items: HeaderItemLeafProps[]
  darkMode?: boolean
}

type HeaderItemProps = HeaderItemGroupingProps | HeaderItemLeafProps

const COLORS: {[env: string]: BootstrapColor} = {
  int: 'primary',
  prod: 'danger',
  preprod: 'warning'
}

const getEnvColor = (env: string | undefined): BootstrapColor => (env && COLORS[env.toLowerCase()]) || COLORS.int

const isLeaf = (props: HeaderItemProps): props is HeaderItemLeafProps => {
  return (props as HeaderItemLeafProps).link !== undefined
}

const HeaderItemGrouping = ({title, items, darkMode}: HeaderItemGroupingProps) => {
  const {pathname} = useLocation()
  const isVisible = items.some((item) => item.show !== false)
  const [isOpen, setOpen] = useState(false)
  const toggle = () => setOpen(!isOpen)
  const currentSelection = items.find((item) => item.link === pathname)?.title

  return isVisible ? (
    <Dropdown dark={booleanToString(darkMode)} nav isOpen={isOpen} toggle={toggle} active={!!currentSelection}>
      <DropdownToggle nav caret data-testid={`${title}-dropdown-toggle`}>
        {title}
        {currentSelection && ` - ${currentSelection}`}
      </DropdownToggle>

      <Submenu dark={booleanToString(darkMode)} tag="ul">
        {items.map(({link, title, show = true}) =>
          show ? (
            <DropdownItem key={link}>
              {link.startsWith('http') ? (
                <NavLink onClick={toggle} href={link} target="_blank">
                  {title}
                </NavLink>
              ) : (
                <NavLink onClick={toggle} tag={RouterNavLink} to={link}>
                  {title}
                </NavLink>
              )}
            </DropdownItem>
          ) : null
        )}
      </Submenu>
    </Dropdown>
  ) : null
}

const HeaderItemLeaf = ({title, link, show = true}: HeaderItemLeafProps) => {
  return show ? (
    <NavItem>
      {link.startsWith('http') ? (
        <NavLink href={link} target="_blank">
          {title}
        </NavLink>
      ) : (
        <NavLink tag={RouterNavLink} to={link}>
          {title}
        </NavLink>
      )}
    </NavItem>
  ) : null
}

const toTopLevelItem = (section: HeaderItemProps, darkMode: boolean) => {
  if (isLeaf(section)) {
    return <HeaderItemLeaf key={section.title} title={section.title} link={section.link} show={section.show} />
  } else {
    return <HeaderItemGrouping items={section.items} title={section.title} key={section.title} darkMode={darkMode} />
  }
}

/**
 * A header that goes on top of any page. This component must be used inside a router.
 */
const Header = ({env, envConfig, fixed, appTitle, sections, children, logo, darkMode}: Props) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <WrappedNavbar dark={darkMode} fixed={fixed ? 'top' : undefined} expand="md">
      <Container>
        <HomepageLink to="/" title={appTitle}>
          <SpaceApeLogo src={spaceApeLogo} />
          <GameLogo>{logo ? logo : <LogoText>{appTitle}</LogoText>}</GameLogo>
        </HomepageLink>

        <NavbarToggler onClick={() => setOpen(!isOpen)} />

        <Collapse isOpen={isOpen} navbar>
          {sections && <Nav navbar>{sections.map((section) => toTopLevelItem(section, darkMode || false))}</Nav>}

          {children}

          <Nav className="ml-auto" navbar>
            {envConfig && (
              <ButtonContainer dark={booleanToString(darkMode)}>
                <EnvironmentSwitcher env={env} config={envConfig} color={getEnvColor(env)} size="sm" darkMode={darkMode} />
              </ButtonContainer>
            )}

            <ButtonContainer>
              <Button color="secondary" size="sm" onClick={() => signOut()}>
                Sign Out
              </Button>
            </ButtonContainer>
          </Nav>
        </Collapse>
      </Container>
    </WrappedNavbar>
  )
}

const booleanToString = (val: boolean | undefined) => (val !== undefined && val === true ? 'true' : 'false')

export default Header
