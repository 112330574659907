import styled from 'styled-components'
import {DropdownMenu, Navbar, NavItem} from 'reactstrap'
import {Link} from 'react-router-dom'
import Colors from '../../constants/colors'

import zIndexes from '../../constants/zIndexes'

export const WrappedNavbar = styled(Navbar)`
  background: ${(props) => (props.dark ? Colors.aura : Colors.albert)};
  color: white;
  position: relative;
  z-index: ${zIndexes.top + 100};

  h1 {
    color: white;
  }
  .nav-link {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
  }
  .nav-link.active {
    color: white;
  }
  .nav-link:hover {
    color: white;
  }

  @media (min-width: 768px) {
    height: 60px;
  }
`

export const Submenu = styled(DropdownMenu)`
  background: ${(props) => (props.dark === 'true' ? Colors.aura : Colors.albert)} !important;

  a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
  }
  .nav-link.active {
    color: white;
  }
  .nav-link:hover {
    color: white;
  }

  .dropdown-item {
    &:hover {
      background-color: inherit;
    }
  }
`

export const HomepageLink = styled(Link)`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  &,
  &:hover,
  &:active {
    color: white;
    font-weight: normal;
  }
`

export const GameLogo = styled.span`
  display: flex;
  height: 40px;
  margin: 0 10px 0 15px;
  justify-content: flex-start;
  align-items: center;

  & > *,
  & img {
    max-height: 100%;
    width: auto;
    flex: 0 0 auto;
  }
`

export const LogoText = styled.h1`
  font-size: 20px;
  margin: 0;
`

export const SpaceApeLogo = styled.img`
  height: 2rem;
`

export const DropdownItem = styled.li`
  padding: 0 10px;
`

export const ButtonContainer = styled(NavItem)`
  margin: 5px 10px 5px 0;

  &:last-child {
    margin-right: 0;
  }
`
